.rendezvouslist-container {
  padding-top: 30px;
  padding-left: 30px;
}
.rendezvous-header-text {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #1a1c1d;
  margin-bottom: 20px;
}
.rendezvous-header {
  display: flex;
  justify-content: space-between;
}
.rendezvous-header-fetchstatus {
}
.rendezvous-header-addrendezvousbutton {
  display: flex;
  width: 220px;
  height: 36px;
  background: linear-gradient(0deg, rgba(84, 70, 219, 0.1), rgba(84, 70, 219, 0.1)),
    #ffffff;
  border-radius: 10px;
  padding-left: 25px;
  padding-right: 0px;
  /*
    padding-top: 8px;
    padding-bottom: 8px;
    */
  text-align: center;
}
.plus {
  padding-bottom: 9px;
  padding-top: 9px;
  margin-right: 6px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
.button_text {
  padding-bottom: 9px;
  padding-top: 9px;
  color: #5446db;
  /* color: #5446DB; */
}
.rendezvous-header-right {
  display: flex;
}

.rendezvous-header-left {
  display: flex;
}
.rendezvous {
  display: flex;
  margin-bottom: 10px;
  width: 100%;
  margin-right: 30px;
}
.clock_logo {
  padding: 9px;
  margin-right: 25px;
  border: 1px solid #eceef5;
  box-sizing: border-box;
  border-radius: 10px;
}
.time_start {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #1a1c1d;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  padding-top: 9px;
  padding-bottom: 9px;
  margin-right: 25px;
}

.time_end {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #8083a3;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  padding-top: 9px;
  padding-bottom: 9px;
  margin-right: 67px;
}
.status {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
  color: #5446db;
  background: linear-gradient(0deg, rgba(84, 70, 219, 0.1), rgba(84, 70, 219, 0.1)),
    #ffffff;
  border-radius: 8px;
  margin-right: 65px;
  width: 90px;
  text-align: center;
}
.actifstatusbutton {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
  color: #1dd2c1;
  background: linear-gradient(0deg, #e8fbf9, #e8fbf9), #ffffff;
  border-radius: 8px;
  margin-right: 65px;
  width: 90px;
  text-align: center;
}
.description {
  padding-top: 9px;
  padding-bottom: 9px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #8083a3;
  /* width: 1000px; */
  width: 900px;
  /* flex-grow: 8; */
  text-align: left;
}
.view_logo {
  padding: 9px;
  margin-right: 10px;
  border: 1px solid #eceef5;
  box-sizing: border-box;
  border-radius: 10px;
  /*flex-grow: 1;*/
}
.more_logo {
  padding: 9px;
  margin-right: 10px;
  border: 1px solid #eceef5;
  box-sizing: border-box;
  border-radius: 10px;
  /*flex-grow: 1;*/
}

.edit_btn {
  width: 76px !important;
  border: none;
  background: #f3f4f9;
  color: #8083a3;
  border-radius: 10px;
  height: 30px !important;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
}

.conto .btn_actif {
  width: 100px !important;
  border: none;
  background: #f3f4f9;
  color: #1dd2c1;
  border-radius: 10px;
  height: 40px !important;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
}
.conto .btn_canceled {
  width: 100px !important;
  border: none;
  background: #f3f4f9;
  color: #a0a0a0;
  border-radius: 10px;
  height: 40px !important;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
}
.conto .btn_completed {
  width: 100px !important;
  border: none;
  background: #f3f4f9;
  color: #a0a0a0;
  border-radius: 10px;
  height: 40px !important;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
}
.conto .btn_update {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  width: 76px !important;
  border: none;
  background: #f3f4f9;
  color: #8083a3;
  border-radius: 10px;
  height: 30px !important;
}

.btn_valid {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  width: 70px ;
  border: none;
  background: #5446db;
  color: white;
  border-radius: 10px;
  height: 30px;
}

.span_actif {
  color: #1dd2c1 !important;
  font-weight: bold !important;
  display: contents !important;
}
.span_completed {
  color: #5446db !important;
  font-weight: bold !important;
}
.span_canceled {
  color: #5446db !important;
  font-weight: bold !important;
}
