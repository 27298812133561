.offers {
  display: flex;
  width: 100%;
}

.offre_actuelle {
  width: 35%;
  background-color: white;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.offre_details {
  width: 65%;
}

.offre_actuelle_info {
  width: 100%;
  background: #f8f9fc;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 33px;
}

.contact_us {
  width: 100%;
  height: 160px;
  border: 1px solid #eceef5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
}

.offre_actuelle_container {
  display: flex;
  flex-direction: column;
}

#offre_actuelle_title {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 42px;
  color: #8083a3;
  padding-left: 20px;
}

.offre_actuelle_info_body {
  padding: 0px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 22px;
}

#offre_actuelle_name {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  color: #5446db;
}

.offre_actuelle_desc {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #8083a3;
  width: 55%;
}

#contact_us_title {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #1a1c1d;
}

#contact_us_subtext {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #8181a5;
}

.send_mail_btn {
  background: #f8f9fc;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65px;
  width: 50%;
}

.send_mail_btn span {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #5446db;
}

.call_us_btn span {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  color: #8083a3;
}

.call_us_btn {
  border: 1px solid #eceef5;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65px;
  width: 50%;
}

.contact_us_buttons {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
}

.send_mail_btn_container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.send_mail_btn_container i {
  color: #5446db;
}

.call_us_btn_container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.call_us_btn_container i {
  color: #8083a3;
}

.contact_us_text {
  display: flex;
  flex-direction: column;
}

.compressed_text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 90%;
}

.offre_details_container {
  height: 100%;
  padding: 20px;
}

.offre_details_content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.offre_list {
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #eceef5;
  border-radius: 8px;
  padding: 20px;
}

#offre_list_header_text_title {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #1a1c1d;
}

#offre_list_header_text_desc {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #8083a3;
}

.offre_list_header_text {
  display: flex;
  flex-direction: column;
}

/*Toggle button*/
.payment_method_right_btn {
  height: 22px;
  width: 40px;
  border-radius: 12px;
  background-color: #1dd2c1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 4px;
  padding-right: 4px;
  transition: 0.6s;
}
.payment_method_right_btn_annuel {
  height: 22px;
  width: 40px;
  border-radius: 12px;
  background-color: #1dd2c1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 4px;
  padding-right: 4px;
  transition: 0.6s;
}

.payment_method_left_btn {
  height: 22px;
  width: 40px;
  border-radius: 12px;
  background-color: #1dd2c1;
  display: flex;
  align-items: center;
  padding-left: 4px;
  padding-right: 4px;
  transition: 0.6s;
}

.btn_circle {
  height: 16px;
  width: 16px;
  left: 4px;
  top: 3px;
  border-radius: 50%;
  background: white;
  transition: 0.6s;
}

#option_off span {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  color: #8083a3;
}

#option_on span {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #222526;
}

.offre_list_header_toggle_btn_container {
  display: flex;
  gap: 10px;
}

.offre_list_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.offer_list_body,
.offer_cards,
.offer_cards_container {
  width: 100%;
  height: calc(100% - 15px);
}

.offer_cards_container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding-top: 3%;
}

.offer_info_details {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.offre_indiv,
.offre_groupe {
  width: 50%;
  background: #ffffff;
  border: 1px solid rgba(84, 70, 219, 0.1);
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.offre_groupe {
  background: #f8f9fc;
  border: 1px solid rgba(84, 70, 219, 0.1);
}

.offre_indiv_title {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 42px;
  text-align: center;
  color: #5446db;
}

.price_num {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 72px;
  line-height: 42px;
  text-align: center;
  color: #5446db;
  padding-top: 5px;
}

.price_tag {
  display: flex;
  align-items: flex-start;
}

.pricing_details span {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  color: #1a1c1d;
  opacity: 0.5;
}

.offer_indiv_details,
.offer_groupe_details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.offer_indiv_details span,
.offer_groupe_details span {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #1a1c1d;
  opacity: 0.7;
}

.activate_indiv_offer_btn {
  border: none;
  width: 60%;
  background: #eceef5;
  border-radius: 10px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #222526;
  margin-bottom: 20px;
}

.activate_groupe_offer_btn {
  border: none;
  width: 60%;
  background: #5446db;
  border-radius: 10px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: white;
  margin-bottom: 20px;
}

.price_tag_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}

.main_content-payment {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1400px) {
  .main_content {
    height: calc(100% - 1.2rem) !important;
  }
}

/*-- xtra features Styling --*/
.stats-content {
  padding: 40px 0px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.patient-stats-cards-content .card-group {
  display: flex;
  gap: 40px;
}
.patient-stats-cards-content .card-group .card,
.patient-graph-cards-content .card-group .card {
  border: none;
  background: #fcfcfc;
  border-radius: 11px !important;
  -webkit-box-shadow: 6px 3px 10px 0px rgba(0, 0, 0, 0.11);
  -moz-box-shadow: 6px 3px 10px 0px rgba(0, 0, 0, 0.11);
  box-shadow: 6px 3px 10px 0px rgba(0, 0, 0, 0.11);
  padding: 20px;
}

.patient-stats-cards-content .card-group .card .patient-stats-card-title h4 {
  color: #5446db;
  font-family: Lato;
  font-weight: 700;
  margin-bottom: 0px !important;
}
.patient-stats-cards-content .card-group .card .patient-stats-card-desc span {
  color: gray;
  font-size: 11px;
  line-height: 0.05em !important;
}
.patient-stats-cards-content .card-group .card .card-body span {
  font-size: 55px;
  color: #5446db;
  font-family: Lato;
  font-weight: lighter;
}
.patient-stats-card-desc button {
  color: gray;
  border: none;
  background: none;
  font-weight: 700;
  font-size: 12px;
}
.active-graph-set-btn {
  color: #5446db !important;
}
.patient-graph-card-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.patient-graph-card-header h5 {
  margin-bottom: 0px;
}
.patient-graph-cards-container {
  width: 100%;
}
.patient-graph-cards-content .card-group {
  display: flex;
  gap: 40px;
}
.patient-graph-cards-content .card-group .card:nth-child(1) {
  flex: 65%;
}

.patient-graph-cards-content .card-group .card:nth-child(2) {
  flex: 30%;
}
.patient-graph-cards-content .card-body {
  width: 100%;
}
.stats-item .container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

/*-- PIE CHART STYLING --*/
.chart-pie-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.chart-pie {
  width: 250px;
  height: 250px;
}
.chart-pie-container img {
  width: 100%;
  height: 100%;
}
.chart-pie-container {
  position: relative;
}
.chart-pie-desc {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.chart-pie-desc h5 {
  color: #1a1c1d !important;
  font-weight: 700;
  font-size: 24px;
  margin: 0px !important;
  padding: 0px !important;
}
.chart-pie-desc p {
  color: gray !important;
  font-weight: 400;
  font-size: 11px;
}

.chart-pie img {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.chart-pie-legend-item {
  display: flex;
  align-items: center;
  gap: 10px;
}
.chart-pie-legend {
  display: flex;
  align-items: center;
  gap: 10px;
}
.color-B {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: black;
}
.stats-item-graph,
.stats-item-graph-content {
  width: 100%;
  height: 100%;
}
.stats-graph {
  width: 100%;
  height: 90%;
}
.stats-graph img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.month-name-graph {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0.5;
}
.month-name-graph span {
  font-size: 12px;
  font-weight: 500;
}
.patient-graph-card-header {
  padding: 1.25rem;
}
.card-body {
  padding: 0px 1.25rem 0px 1.25rem !important;
}
.chart-pie-legend-item span {
  font-size: 14px;
  font-weight: 500;
}
.patient-stats-card-desc {
  font-size: 14px;
}
.patient-stats-card-desc button {
  font-family: lato;
  font-weight: 800;
  color: lightgray;
}
.patient-graph-cards-content .card:nth-child(2) .patient-graph-card-header {
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}
.patient-graph-cards-content
  .card:nth-child(2)
  .patient-graph-card-header
  .Transactions-header {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.patient-stats-cards-content .card {
  height: 155px;
}
.patient-stats-cards-content .card-group {
  display: flex;
  align-items: center;
}
.stats-item .patient-stats-cards-container {
  width: 100%;
}
.patient-stats-cards-content .card-group .card {
  flex: 35%;
}

.card-type img {
  width: 60px;
}

.patient-credit-card-transaction-content {
  display: flex;
  gap: 40px;
  background-color: #fcfcfc;
  border-radius: 11px !important;
  -webkit-box-shadow: 6px 3px 10px 0px rgb(0 0 0 / 11%);
  -moz-box-shadow: 6px 3px 10px 0px rgba(0, 0, 0, 0.11);
  box-shadow: 6px 3px 10px 0px rgb(0 0 0 / 11%);
  padding: 20px;
}

.patient-credit-card-transaction-container,
.patient-credit-card-transaction-content {
  width: 100%;
}
.patient-credit-card-transaction-content .patient-credit-card-info {
  flex: 35%;
}
.patient-credit-card-transaction-content .patient-recent-transaction {
  flex: 60%;
}
.patient-credit-card-info-body-content {
  background: rgb(236, 236, 236);
  border-radius: 11px;
  height: 150px;
  padding: 10px 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}
.card-info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.patient-credit-card-info-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.patient-recent-transaction {
  padding: 0px 10px;
}
.patient-recent-body-content {
  overflow: auto;
}
.patient-recent-body-content .patient-transaction-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.transaction-details {
  display: flex;
  align-items: center;
  gap: 40px;
}
.patient-recent-body-content {
  max-height: 150px;
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.patient-recent-transaction {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.patient-transaction-item span {
  font-size: 14px;
  font-family: Lato;
  font-weight: 600;
  opacity: 0.6;
}
.stats-item h5 {
  margin-bottom: 0px;
  font-family: lato;
  font-weight: 700;
}
.container {
  max-width: 95% !important;
}

@media screen and (min-width: 1440px) and (max-width: 1650px) {
  .chart-p {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .chart-pie {
    width: 350px;
    height: 350px;
  }
  .chart-pie-desc h5 {
    font-size: 35px;
  }
}
.card-info span {
  font-family: lato;
  font-weight: 700;
  font-size: 14px;
  opacity: 0.8;
}

@media only screen and (min-width: 1649px) {
  .stats-content {
    flex-direction: row;
  }
  .patient-credit-card-transaction-content {
    flex-direction: column;
  }
  .container {
    max-width: 100% !important;
  }
  .stats-item:nth-child(1) {
    flex: 70%;
  }
  .stats-item:nth-child(2) {
    flex: 20%;
  }
  .stats-content {
    width: 98%;
  }
  .stats-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .container,
  .row {
    height: 100%;
  }
  .patient-credit-card-transaction-container,
  .patient-credit-card-transaction-content {
    height: 100%;
  }
  .patient-recent-body-content,
  .patient-recent-body {
    max-height: 400px;
  }
  .chart-pie-legend-item span {
    font-size: 12px;
    font-weight: 500;
  }
}

@media only screen and (min-width: 1700px) {
  .stats-content {
    width: 95%;
  }
}

@media only screen and (min-width: 1800px) {
  .stats-content {
    padding-top: 50px;
  }
  .chart-p {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .chart-pie {
    width: 350px;
    height: 350px;
  }
  .chart-pie-desc h5 {
    font-size: 35px;
  }
}
@media only screen and (min-width: 1920px) {
  .stats-content {
    padding-top: 70px;
  }
}

.sidenav {
  overflow-y: auto;
  overflow-x: hidden !important;
}
.item_section_right {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.item_section_right button {
  text-align: left;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  background: transparent !important;
  border: none !important;
  color: #8083a3;
  font-size: 15px;
  padding: 0px;
}
.navbar-collapse ul {
  width: 80% !important;
}
.navbar-collapse {
  display: flex;
  justify-content: flex-end;
}
.navbar-collapse .nav-item button {
  display: flex;
  align-items: center;
  gap: 10px;
  border: none;
  background: none;
}

.navbar-collapse ul {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.nav-item button span {
  font-size: 14px;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  color: #8083a3;
}
