.appointment_settings_content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.appointment_settings {
  border-right: 1px solid #eceef5;
}
.appointment_settings_top_section_container {
  padding-top: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}

/*Top section Active item*/
.activated_item {
  background: #f8f9fc;
}

.activated_item #main_title {
  color: #5446db;
}

/*Top section items Styling*/
.top_section_item {
  display: flex;
  align-items: flex-start;
  height: 72px;
  width: 298px;
  border-radius: 6px;
  cursor: pointer;
  justify-content: space-between;
  padding-right: 20px;
}
.top_section_item_left_section {
  display: flex;
  align-items: center;
  height: 72px;
  border-radius: 6px;
  cursor: pointer;
}
.top_section_item_text {
  display: flex;
  flex-direction: column;
}
.top_section_item_text #main_title {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px !important;
  color: #1a1c1d;
}
.top_section_item_text #sub_title {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #8083a3;
}

.icon_agenda {
  margin-left: 30px;
  margin-right: 20px;
}
.badge {
  margin-top: 10px;
  background: #1dd2c1 !important;
}

#top_section_seperator {
  width: 100%;
  height: 0.5px;
  background: #eceef5;
  opacity: 0.5;
}

/*Bottom section items Styling*/
.appointment_settings_bottom_section {
  width: 100%;
  height: 280px;
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  margin-bottom: 20px;
}

.agenda_header span {
  font-size: 18px !important;
  color: #1a1c1d !important;
  font-weight: 700;
  width: 50%;
}
.agenda_header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 273px;
  margin-left: 5%;
}
.agenda_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
}

.agenda_header_control {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.agenda_header_icon {
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.agenda_days {
  display: flex;
}

#agenda_days_item {
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
}
.agenda_dates {
  display: flex;
}
#agenda_dates_item {
  width: 40px;
  display: flex;
  align-items: center;
  height: 50px;
  display: flex;
  flex-direction: column;
}
#agenda_dates_item span {
  font-family: Lato;
  font-weight: 700;
  font-size: 13px;
  color: #1a1c1d;
}
.first_color_dot {
  background: #eceef5;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}
.second_color_dot {
  background: #5446db;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}
.color_dot_container {
  display: flex;
  width: 20px;
  justify-content: space-around;
}
.third_color_dot {
  background: #f6933e;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}
.fourth_color_dot {
  background: #ff808b;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}
#agenda_date_container {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#agenda_date_container:hover {
  cursor: pointer;
}
.selected_date {
  background: #5446db !important;
}
.selected_date span {
  color: white !important;
}
.agenda_days #agenda_days_item span {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 18px;
  /* identical to box height, or 164% */

  color: #8083a3;
}

.actual-agenda {
  display: flex;
  gap: 5px;
}

.agenda-content {
  display: flex;
  align-items: center;
}
.actual-agenda {
  overflow-y: auto;
  width: calc(100vw - 440px) !important;
  margin-top: -78px;
}
.agenda-content .container {
  max-width: 100% !important;
}
.agenda-content .row {
  width: max-content;
  gap: 20px !important;
}

.actual-agenda .agenda-col {
  width: 300px !important;
}
.agenda-days-item {
  height: 80px;
}
.agenda-header .agenda-days-item {
  width: 100%;
}
::-webkit-scrollbar {
  height: 10px;
}

.appointments-timing {
  height: calc(100vh - 64px);
}
.patient-list-info-container {
  width: 100%;
}

.patient-ag-item {
  height: 70px;
  border-left: 5px black solid;
  border-radius: 8px;
  padding: 6px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.patient-ag-item .patient-name {
  font-size: 18px;
  font-weight: bold;
}
.patient-ag-item .patient-info {
  font-size: 13px;
}

.agenda-appointment-container {
  overflow: hidden;
}
.agenda-appointment-container {
  overflow-y: auto;
}

.agenda-row-col-container,
.agenda-col-content {
  padding-bottom: 10px;
}

.appointment_details_container {
  padding-top: 40px;
  height: calc(100vh - 64px);
  overflow-y: auto;
  overflow-x: hidden;
}

.agenda-row-col-container {
  overflow-x: auto;
}
.agenda-appointment-container {
  display: flex;
  gap: 20px;
}
.agenda-row-col-container {
  display: flex;
  gap: 10px;
}

.agenda-row-content {
  display: flex;
  gap: 10px;
}
.agenda-row .appointments-content {
  display: flex;
}

.appointments-timing .agenda-col-item {
  width: 60px;
}
.appointments-content {
  width: 100%;
}

.agenda-content {
  display: flex;
  align-items: center;
}

.agenda-days-item {
  height: 100px;
}
.appointments-timing .agenda-col-item,
.daily-appointments .agenda-col-item {
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.daily-appointments,
.daily-appointments .agenda-col-content {
  width: 280px !important;
}

.agenda-days-item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.day-hour {
  display: flex;
  align-items: center;
  justify-content: center;
}

.appointments-timing .agenda-col-item span,
.agenda-days-item {
  font-family: lato;
  font-weight: 600;
  color: grey;
  font-size: 14px;
}
.empty-appointment {
  background: lightgray;
}
.agenda-daily-appointments {
  display: flex;
  gap: 10px;
}
.daily-appointment-content {
  width: 100%;
  height: 100%;
  padding: 10px;
}
.appointment-timing span {
  font-family: lato;
  font-weight: 700;
  font-size: 15px;
}
.patient-name span {
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.03em;
  font-family: lato;
}
.active-appointment {
  /*background: rgb(85, 85, 252);*/
  color: black;
  cursor: pointer;
  border: 1px solid green;
}
.active-appointment-finished {
  /*background: rgb(85, 85, 252);*/
  color: black;
  cursor: pointer;
  border: 1px solid #5446db;
}
.active-appointment-canceled {
  /*background: rgb(85, 85, 252);*/
  color: black;
  cursor: pointer;
  border: 1px solid orange;
}

/*-- Timing (9:15mins) -- */
.quarter-plus-init-time {
  margin-top: 25px;
}
/*-- Timing (9:30mins) -- */
.half-plus-init-time {
  margin-top: 50px;
}

.half-minus-init-time {
  height: 50px !important;
}
.quarter-minus-init-time {
  height: 75px !important;
}
.agenda-col-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.agenda-container .row {
  display: flex;
  gap: 5px;
}
*:focus {
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
}
.not_app_container_header {
  width: 100%;
  background-color: yellow;
  padding: 30px 0px;
}
.not_app_container_header h3 {
  text-align: center;
  text-transform: capitalize;
}

.appointment_details_container {
  width: 100%;
}
.not_app_container {
  width: 100%;
  height: calc(100vh - 66px);
}

.not_app_container_body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80%;
}
img {
  vertical-align: middle;
  border-style: none;
}
#appointment_details_indispo {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 42px;
  text-align: center;
  margin-bottom: 10px;
  color: #1a1c1d;
}
#appointment_details_sub {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #8083a3;
  margin-bottom: 20px;
  width: 350px;
}
#add_rv {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  padding: 10px 30px !important;
  border: none;
  background: #5446db;
  color: white;
  border-radius: 10px;
}
