.form-control:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  color: #8083a3 !important;
}

.contact_patients_header p {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #8083a3;
}

.contact_patients_header span {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #1a1c1d;
  margin-right: 150px;
}

.contact_patients_header {
  margin-bottom: 20px;
  margin-top: 20px;
  height: 60px;
  width: 88%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;

  justify-content: center;
}

.search_contact_patients {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search_contact_patients input {
  color: #1a1c1d;
  border: none;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  width: 95%;
}

.contact_patients {
  width: 40% !important;
  height: calc(100vh - 64px);
  background-color: white;
  border-right: 1px solid #eceef5;
}

.contact_patients_controls {
  width: 90%;
  height: 60px;
  display: flex;
  gap: 10px;
  margin-right: auto;
  margin-left: auto;
  align-items: center;
}

.contact_patients_body {
  width: 100%;
  height: auto;
  overflow-y: scroll;
}

.contact_patients_body_item {
  width: 95%;
  height: 80px;
  margin: 10px 0px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  border-radius: 12px;
  align-items: center;
  padding: 0px 10px;
}

.contact_patients_body_item:hover {
  background: #f8f9fc;
}

.contact_patients_body_item:hover > .call_icon {
  background: linear-gradient(0deg, rgba(84, 70, 219, 0.1), rgba(84, 70, 219, 0.1)),
    #ffffff;
}

.contact_patients_body_item:hover > .call_icon i {
  color: #5446db !important;
}

.patient_contact_info_container {
  display: flex;
  gap: 20px;
}

.patient_contact_img_container {
  width: 40px;
  height: 40px;
  background-color: #eceef5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.patient_contact_img_container img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.patient_contact_info {
  display: flex;
  flex-direction: column;
}

#patient_contact_name {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #1a1c1d;
}

#patient_contact_city {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #8083a3;
}

.call_icon i,
.more_icon i {
  color: #8083a3;
}

.call_icon,
.more_icon {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #eceef5;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}

.contact_patients_controls .form-control,
.patients_bilans_record_sub_header .form-control {
  background: #ffffff !important;
  border: none;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #8083a3;
}

.contact_patients_controls select,
.patients_bilans_record_sub_header select {
  border: none;
}

/*.patient_detailed_info_section {
  padding-top: 40px;
}*/

.patient_detailed_info_section_header {
  display: flex;
  height: 120px;
  gap: 20px;
  margin-left: 130px;
}

.patient_info {
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.patient_bilan_tags,
.patient_bilan_text {
  display: flex;
  gap: 10px;
  margin-left: 20px;
}

.patient_avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: #eceef5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.patient_avatar img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
}

.patient_general_info {
  display: flex;
  flex-direction: column;
}

#patient_general_info_name {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 160% */

  color: #1a1c1d;
}

#patient_general_info_city {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */

  color: #8083a3;
}

.patient_general_info_btns {
  display: flex;
  gap: 10px;
}

.np_btn {
  background-color: #5446db;
  width: 220px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
}

.np_btn span {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;

  color: #ffffff;
}

.patient_detailed_info_section_body_item {
  width: 95%;
  height: 60px;
}

#patient_email,
#patient_birthday,
#patient_cnam,
#patient_address,
#patient_phone {
  width: 110px;
}

.edit_button {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  font-size: 12px !important;
  color: #5446db !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  padding: 5px 10px;
}

.edit_button span {
  color: inherit !important;
  font-size: inherit !important;

  /*padding:0px 10px 0px 0px;*/
  margin-left: 10px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
}

.edit_button i {
  font-size: 14px;
}

#note_label,
#patient_detail_props_label {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */

  text-align: right;

  color: #8083a3;
}

#note_text,
#patient_detail_props_text {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;

  color: #1a1c1d;
}

.patient_detailed_info_section_body_item {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  padding-top: 10px;
}

.patient_info_note {
  margin-top: 50px;
}

.note,
.patient_detail_props {
  display: flex;
  gap: 40px;
}

.note_label_container {
  margin-left: 78px;
  text-align: right;
}

.phone_label_container,
.address_label_container,
.cnam_label_container,
.birthday_label_container,
.email_label_container {
  width: 180px;
  text-align: right;
}

.preview_containers {
  display: flex;
  margin: 0px 50px;
  gap: 20px;
  margin-bottom: 20px;
  margin-top: 50px;
}

.bilans_preview_container,
.bilans_preview_container {
  width: 50%;
  height: 40%;
  border: 1px solid #eceef5;
  border-radius: 8px;
}

.bilans_preview_header,
.bilan_preview_header {
  display: flex;
  justify-content: space-between;
  margin: 15px;
}

.bilans_preview_header .left span,
.bilan_preview_header .left span {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  /* or 150% */

  color: #1a1c1d;
}

.bilans_preview_header .right span,
.bilan_preview_header .right span {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 21px;
  /* or 150% */

  color: #5446db;
}

.consultation_preview_container,
.bilan_preview_container {
  padding: 20px 0px 20px 0px;
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.indispo_more_details i:hover {
  background-color: #eceef5;
  color: #5446db;
}

.bilans_preview_item,
.bilan_preview_item {
  display: flex;
  justify-content: space-between;
}

.indispo_text_details {
  height: 36px;
  display: flex;
  align-items: center;
  width: 80%;
}

.indispo_text_details span {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #8083a3;
}

#patient_consulation_timing #start_time {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #1a1c1d;
}

#patient_consulation_timing #end_time {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #8083a3;
}

#patient_consulation_timing i {
  padding: 10px;
  border: 1px solid #eceef5;
  color: #8083a3;
  border-radius: 10px;
  background-color: white;
}

#patient_consulation_timing {
  display: flex;
  align-items: center;
  gap: 10px;
}

#patient_bilans_record_timing {
  display: flex;
  align-items: center;
  gap: 20px;
}

#patient_bilans_record_timing #start_time {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #1a1c1d;
}

#patient_bilans_record_timing #end_time {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #8083a3;
}

#patient_bilans_record_timing i {
  padding: 10px;
  border: 1px solid #eceef5;
  color: #8083a3;
  border-radius: 10px;
  background-color: white;
}

.patients_bilans_record_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  height: 67vh;
}

.text_clock_cont {
  display: flex;
  flex-direction: column;
}

.patient_bilan_tags,
.patient_bilan_text {
  margin-right: 1%;
  display: flex;
  gap: 10px;
  margin-left: 40px;
}

.patient_consultation_text #type_consultation,
.patient_bilan_text #bilan_name {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #1a1c1d;
}

.patient_consultation_text #patient_name,
.patient_bilan_text #bilan_details {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  color: #8083a3;
}

.right {
  display: flex;
  gap: 10px;
  align-items: center;
}

.patient_info_email,
.patient_info_birthday,
.patient_info_cnam,
.patient_info_address,
.patient_info_phone {
  height: 30px !important;
}

.selected_patient {
  background: #f8f9fc;
}

.selected_patient > .call_icon {
  background: linear-gradient(0deg, rgba(84, 70, 219, 0.1), rgba(84, 70, 219, 0.1)),
    #ffffff;
}

.selected_patient > .call_icon i {
  color: #5446db !important;
}

.test_seperator {
  background: #eceef5;
  width: 2px;
  height: 25px;
  margin-right: 10px;
  margin-left: 15px;
}

.patient_basic_info #patient_name {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  /* or 178% */

  color: #1a1c1d;
}

.patient_basic_info #patient_city {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 21px;
  /* or 162% */

  color: #8083a3;
}

.patients_bilans_record_label {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #1a1c1d;
}

.patients_bilans_record_sub_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.patients_bilans_record_sub_header .form-control {
  width: 20%;
}

.bilans_preview_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 2% 20px;

  border-radius: 12px;
}

.bilans_preview_item:hover {
  background: #f8f9fc;
}

.patients_bilans_record_header {
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 20px 0px;
}

.patient_basic_info {
  display: flex;
  flex-direction: column;
}

.patients_bilans_record_container,
.patients_bilans_record_header,
.patients_bilans_record_sub_header {
  padding: 0px 20px;
}

.patient_img img {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 50%;
}

@media screen and (min-width: 1440px) {
  .patients_bilans_record_container {
    height: 74vh;
  }
}

@media screen and (min-width: 1600px) {
  .patients_bilans_record_container {
    height: 75.5vh;
  }
}

@media screen and (min-width: 1920px) {
  .patients_bilans_record_container {
    height: 79vh;
  }
}

.form-control:focus {
  outline: none !important;
}

select:focus {
  outline: none !important;
  border: none !important;
}

.tags {
  height: 36px;
  width: 96px;
  background: linear-gradient(0deg, rgba(129, 156, 255, 0.1), rgba(129, 156, 255, 0.1)),
    #ffffff;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tags span {
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  color: #819cff;
}


.bilans_preview_item:hover > .recent_patient_icon {
  display: flex;
}
.bilans_preview_item:hover > .recent_patient_icon i {
  color: #5446db !important;
}
.bilans_preview_item:hover > .recent_patient_icon i {
  color: #5446db !important;
}
