
.form-control:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    color: #8083a3 !important;
}
.selected_facture_details {
    width: 95%;
    height: 80px;
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
    border-radius: 12px;
    align-items: center;
    padding: 0px 10px;
    background: #F8F9FC;
    margin-top:15px;
    margin-bottom:30px;
}


.facture_patient_info_container, .selected_facture_info_container {
    display: flex;
    gap: 20px;
}

.facture_patient_img_container, .selected_facture_img_container {
    width: 40px;
    height: 40px;
    background-color: #ECEEF5;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.facture_patient_img_container img, .selected_facture_img_container img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.facture_patient_info, .selected_facture_patient_info{
    display: flex;
    flex-direction: column;

}

#facture_patient_name, #selected_facture_patient_name{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: #1A1C1D;
}

#facture_patient_city, #selected_facture_patient_city{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #8083A3;

}

.facture_patient_controls .form-control {
    background: #ECEEF5;
    border: none ;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: #8083A3;
}

.facture_patient_controls select {
    border: none;
}

.fatcure_detailed_info_section {
    padding-top: 40px;
}

.patient_detailed_info_section_header {
    display: flex;
    height: 120px;
    gap: 20px;
    margin-left: 145px;
}

.patient_info {
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.facture_info_section_form {
    width: 100%;
    height: calc(100vh - 64px);
    overflow-y: auto;
}



.patient_avatar img {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 50%;
}

.facture_general_info {
    display: flex;
    flex-direction: column;

}

#facture_general_info_name {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    /* identical to box height, or 160% */


    color: #1A1C1D;

}

#facture_general_info_city {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */


    color: #8083A3;
}

.facture_general_info_btns {
    display: flex;
    gap: 10px;
}

.np_btn {
    background-color: #5446DB;
    width: 220px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
}

.np_btn span {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;

    color: #FFFFFF;

}
.patient_detailed_info_section_body{
    margin-top:30px;
}
.patient_detailed_info_section_body_item {
    width: 95%;
}

#patient_email,
#patient_birthday,
#patient_cnam,
#patient_address,
#patient_phone {
    width: 110px;
}

.payment_tag {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    font-size: 12px !important;
    color: #5446DB !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 10px;
    padding: 5px 10px;

}

.edit_button span {
    color: inherit !important;
    font-size: inherit !important;

    /*padding:0px 10px 0px 0px;*/
    margin-left: 10px;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
}

.edit_button i {
    font-size: 14px;

}

#note_label,
#patient_detail_props_label {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */

    text-align: right;

    color: #8083A3;
}

#note_text,
#patient_detail_props_text {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;

    color: #1A1C1D;
}

.patient_detailed_info_section_body_item {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    padding-top: 10px;
}

.patient_info_note {
    margin-top: 50px;
}

.note,
.patient_detail_props {
    display: flex;
    gap: 40px;
}

.note_label_container {
    margin-left: 78px;
    text-align: right;
}

.phone_label_container,
.address_label_container,
.cnam_label_container,
.birthday_label_container,
.email_label_container {
    width: 180px;
    text-align: right;
}


.preview_containers {
    display: flex;
    margin: 0px 50px;
    gap: 20px;
    margin-bottom: 20px;
    margin-top: 50px;
}

.consultations_preview_container,
.bilans_preview_container {
    width: 50%;
    height: 40%;
    border: 1px solid #ECEEF5;
    border-radius: 8px;
}

.consultations_preview_header,
.bilan_preview_header {
    display: flex;
    justify-content: space-between;
    margin: 15px;
}

.consultations_preview_header .left span,
.bilan_preview_header .left span {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    /* or 150% */


    color: #1A1C1D;
}

.consultations_preview_header .right span,
.bilan_preview_header .right span {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 21px;
    /* or 150% */


    color: #5446DB;
}

.consultation_preview_container,
.bilan_preview_container {
    padding: 20px 0px 20px 0px;
    border-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.indispo_more_details i:hover {
    background-color: #ECEEF5;
    color: #5446DB;

}


.indispo_text_details {
    height: 36px;
    display: flex;
    align-items: center;
    width: 80%;
}

.indispo_text_details span {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: #8083A3;
}

#patient_consulation_timing #start_time {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: #1A1C1D;

}

#patient_consulation_timing #end_time {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #8083A3;
}

#patient_consulation_timing i {
    padding: 10px;
    border: 1px solid #ECEEF5;
    color: #8083A3;
    border-radius: 10px;
    background-color: white;
}

#patient_consulation_timing {
    display: flex;
    align-items: center;
    gap: 10px;
}

.text_clock_cont {
    display: flex;
    flex-direction: column;
}

.patient_consultation_text,
.patient_bilan_text {
    display: flex;
    flex-direction: column;

}

.more_icon_container i {
    padding: 10px;
    border: 1px solid #ECEEF5;
    color: #8083A3;
    border-radius: 10px;
    background-color: white;
}

.patient_consultation_text #type_consultation,
.patient_bilan_text #bilan_name {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #1A1C1D;
}

.patient_consultation_text #patient_name,
.patient_bilan_text #bilan_details {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */


    color: #8083A3;
}

.right {
    display: flex;
    gap: 10px;
    align-items: center;
}

.patient_info_email,
.patient_info_birthday,
.patient_info_cnam,
.patient_info_address,
.patient_info_phone {
    height: 30px !important;
}

.selected_patient {
    background: #F8F9FC;
}

.selected_patient>.call_icon {
    background: linear-gradient(0deg, rgba(84, 70, 219, 0.1), rgba(84, 70, 219, 0.1)), #FFFFFF;
}

.selected_patient>.call_icon i {
    color: #5446DB !important;
}

.test_seperator {
    background: #ECEEF5;
    width: 2px;
    height: 25px;
    margin-right: 10px;
    margin-left: 15px;
}
.compressed_text{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width:90%;
}

.fatcure_detailed_info_section{
    width:100%;
    height: calc(100vh - 64px);
    /*display:flex;
    justify-content: center;
    align-items: center;*/
}
.empty_facture_result_container{
    width:266px;

}
.empty_facture_result{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    gap:10px;
}

#no_facture{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 42px;
    text-align: center;
    margin-bottom: 10px;
    color: #1A1C1D;
}
#no_facture_desc{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #8083A3;
    margin-bottom: 20px;
    width: 350px;
}
#add_facture {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    padding: 10px 30px !important;
    border: none;
    background: #5446DB;
    color: white;
    border-radius: 10px;
}
.facture_payment_details_container{
    width: 83%;
}
.facture_payment_details{
    display:flex;
    width:100%;
}
.payment_tag_container{
    display:flex;
    flex-direction:column;
    justify-content: flex-end;
}
.payment_checkout{
    width:87%;
    height:80px;
    margin-left: auto;
    margin-right: auto;
    border-radius:10px;
    display:flex;
    gap:15px;
    padding-left: 15px;
    padding-right:15px;
    align-items:center;
    border: 1px solid #ECEEF5;
}
.code_facture{
    width:14%;}
.code_facture span{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */


    color: #1A1C1D;

}
.event{
    width:27%;
}
.event span{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    /* or 123% */


    color: #1A1C1D;
}
.date{
    display:flex;
    flex-direction:column;
    width:18%;
}
.date span{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    /* or 131% */


    color: #1A1C1D;

    opacity: 0.5;
}

.seance{
    width:13%;
}
.seance span{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height, or 123% */

    text-align: right;

    color: #1A1C1D;

    opacity: 0.5;
}
.prix span{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */


    color: #1A1C1D;

}
@media screen and (min-width: 1400px){
    .code_facture{
        width:15%;}
    .event{
        width:30%;
    }
}
@media screen and (min-width: 1800px){
    .event{
        width:35%;
    }
}

.form-row{
    margin-left: 0px !important;
    margin-right: 0px !important;
    gap:25px;
}
.add_facture_section{
    padding:20px;
}
.add_facture_section form{
    width:100%;
}
.add_facture_section form .form-group{
    width:46.5%;
}
.add_facture_section .input_and_icon input{
    width:90%;
}
.input_and_icon input{
    height:2vw;
    border: none;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    padding-bottom:10px;
    color: #1A1C1D;
    width:27vw;
}
.input_and_icon{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    border-bottom: 1px#ECEEF5 solid;
}
.input_and_icon i {
    color:#8083A3;
}
form label{
    margin-bottom: 0.5vw;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    /* identical to box height, or 150% */


    color: #8083A3;
}
.add_facture_section select {
    border: none !important;
    border-bottom: 1px solid #ECEEF5 ;
    padding:0px !important;
    font-size:16px;
    font-family: Lato !important;
    font-weight: bold !important;
    font-size: 16px !important;
    color: #373839 !important;
    border-radius:0px !important;
}
.start_end_date_select_container{
    width:46.5%;
    display:flex;
    gap:20px;
}
.start_end_date_select_container .form-group{
    width:50% !important;
}
.add_facture_section input{
    padding-bottom:15px !important;
}
.start_end_date_select_container select{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    padding-bottom:15px !important;
    color: #8083A3 !important;

}
.form-group select:focus{
    color: #373839 !important;
    border-bottom: 1px solid #ECEEF5 !important;
}
.footer_buttons{
    margin-top:30px;
    margin-bottom:30px;
    display:flex;
    flex-direction: row;
    width:96.5%;
    justify-content: space-between;}


.footer_buttons button{
    border:none;
    font-family: Lato;
    font-style: normal;

    width:120px;
    height:40px;
    border-radius: 10px;
    font-weight: 700;
    text-align: center;

}

.footer_buttons #cancel{
    background-color:white;
    color:#8083A3;
    border:1px solid #8083A3;

}

.footer_buttons #update{
    color:white;
    background-color: blue;

}

.footer_buttons #cancel:focus{
    border: 1px solid #8083A3 !important;
}

.form_subtitle span{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size:18px !important;
    line-height: 27px;
    color:#1A1C1D !important;

}
.form_subtitle{
    margin-top:30px;
    margin-bottom:30px;
}

.form-group label{

}
