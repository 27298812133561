/*no border or outline on focus*/
*:focus {
  border: none;
  outline: none;
}
.form-group {
  width: 100%;
}
select:focus {
  outline: none !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  border-color: #eceef5 !important;
}
.type1 select:focus {
  color: #8083a3 !important;
}
.type2 select:focus {
  color: #1a1c1d !important;
}

.ui_elems_inputs,
ui_elems_select {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}
h1 {
  margin-bottom: 30px !important;
  margin-top: 30px !important;
}

/*input style*/
.ui_elem_input {
  margin: 10px 20px 0px 0px;
  transition: 0.2s;
  /*border-bottom: 1px solid #ECEEF5;*/
  width: 500px;
}
.select_container_item {
  margin: 10px 20px 0px 0px;
  transition: 0.2s;
  /*border-bottom: 1px solid #ECEEF5;*/
  width: 500px;
}
label {
  margin-bottom: 0.5vw;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #8083a3;
}
.input_and_icon input {
  height: 2vw;
  border: none;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  padding-bottom: 10px;
  color: #1a1c1d;
  width: 100%;
}
.input_and_icon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px#ECEEF5 solid;
}
.input_and_icon i {
  color: #8083a3;
}

.type3 .input_and_icon {
  border-bottom: 1px solid #ff808b;
}
.type4 .input_and_icon {
  border-bottom: 1px solid #1dd2c1;
}

/* SELECT */

.select_container_item select {
  border: none;
  border-bottom: 1px solid #eceef5;
  padding-left: 0px;
  font-weight: 700;
}
.type1 select {
  color: #8083a3 !important;
}
.type2 select {
  color: #1a1c1d !important;
}

/*TOASTS*/

.select_container_item {
  height: 90px;
  width: 487px;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
}
.left_section {
  height: 100%;
  width: 50px;
  display: flex;
  justify-content: center;
  margin-top: 23px;
}

.toast_title {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 17px;
  color: #ffffff;
  margin-bottom: 6px;
}
.right_section {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.sub_desc {
  width: 100%;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.9;
}

.toast_succ {
  background: #1dd2c1;
}
.toast_error {
  background: #ff808b;
}
.toast_warning {
  background: #f6933e;
}
.toast_info {
  background: #819cff;
}

.ui_elems_buttons {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

/*switch buttons*/
.deactive_btn {
  height: 22px;
  width: 40px;
  border-radius: 12px;
  background-color: #f3f4f9;
  display: flex;
  align-items: center;
  padding-left: 4px;
  padding-right: 4px;
}
.deactive_btn_circle {
  height: 16px;
  width: 16px;
  left: 4px;
  top: 3px;
  border-radius: 50%;
  background: #8083a3;
}
.active_btn {
  height: 22px;
  width: 40px;
  border-radius: 12px;
  background-color: #5446db;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 4px;
  padding-right: 4px;
}
.active_btn_circle {
  height: 16px;
  width: 16px;
  left: 4px;
  top: 3px;
  border-radius: 50%;
  background: white;
}
.switch_button,
.checkbox_buttons,
.radio_buttons {
  display: flex;
  flex-direction: row;
  gap: 50px;
}

/*Checkbox*/
.unchecked_checkbox span {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #8181a5;
}
.active_checkbox span {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #1a1c1d;
}
.actual_checkbox {
  height: 20px;
  width: 20px;

  border-radius: 4px;
  background: #5547da;
  display: flex;
  justify-content: center;
  align-items: center;
}
.active_checkbox,
.unchecked_checkbox {
  display: flex;
  gap: 12px;
}
.unchecked_checkbox .actual_checkbox {
  background: #eceef5;
}

/*Radio buttons*/
.actual_active_radio_btn {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #5547da;
  display: flex;
  justify-content: center;
  align-items: center;
}
.active_radio_btn,
.unchecked_radio_btn {
  display: flex;
  gap: 12px;
}
.active_radio_btn span {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #1a1c1d;
}
.radio_btn_circle {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: white;
}
.unchecked_radio_btn .actual_unchecked_radio_btn {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #f0f0f3;
}
.unchecked_radio_btn span {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #8083a3;
}
