
.user_settings{
   /* height:100%;*/
    width:100%;
    display: flex;
    flex-direction: row;
    border-left: 1px solid #ECEEF5;
    overflow-x: hidden;
    overflow-y: auto;
}

.container{
    margin:30px;

}
form{
    display:flex;
    flex-direction: column;
}
.add_patient_info{
    /*margin-top:3vw;*/
    margin-left:3vw;
    display: flex;
    flex-direction: column;
    width:97% !important;
    /*height:91%;*/
    align-items: center;
    justify-content: space-between;
    margin-right:20vw;
    padding-right:20px;

}
.title_and_button{
    display:flex;
    flex-direction: row;
    width:100%;
    justify-content: space-between;
    margin-bottom:2vw !important;
}

.title_and_button button{
    position: fixed;
    right:50px;
    top:115px;
    border: none;
    background: linear-gradient(0deg, rgba(84, 70, 219, 0.1), rgba(84, 70, 219, 0.1)), #FFFFFF;
    border-radius: 10px;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;

    color: #5446DB;
    background: transparent;


}
.edit_button{
    display:flex;
    flex-direction: row;
    cursor: pointer;

}
#general_info_title{
    font-size:18px !important;
    color: #1A1C1D !important;

}

.title_and_button i {
    margin:10px;
}

.title_and_button span{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size:14px !important;
    line-height: 27px;
    color: #5446DB !important;

}

.add_patient_form_item{
    display:flex;
    flex-direction: column;

    margin: 10px 20px 0px 0px;
    transition:.2s;
}
.add_patient_form{
    width:100%;
}

.add_patient_actual_form{
    display:flex;
    flex-wrap: wrap;
    width:100%;/*
    margin-bottom:1vw;*/
}
.add_patient_form_item label{
    margin-bottom: 0.5vw;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    /* identical to box height, or 150% */


    color: #8083A3;
}
.input_and_icon input{
    height:2vw;
    border: none;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    padding-bottom:10px;
    color: #1A1C1D;
    width:27vw;
}
.edit_button{
    font-size: 16px !important;
    line-height: 19px;
    color: #5446DB !important;
    display:flex;
    flex-direction: row;
    align-items: center;
    border-radius: 10px;
    background: linear-gradient(0deg, rgba(84, 70, 219, 0.1), rgba(84, 70, 219, 0.1)), #FFFFFF;
    margin-right: 30px;
}
.edit_button span{
    color:inherit !important;
    font-size: inherit !important;
    line-height: inherit;
    padding:0px 10px 0px 0px;
}
.edit_button i{
    font-size: 14px;

}
.input_and_icon{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    border-bottom: 1px#ECEEF5 solid;
}
.input_and_icon i {
    color:#8083A3;
}
input:focus{
    outline: none;
}
.address_form span{
    font-family: Lato;
    font-weight: 700;
    font-size:18px;
    line-height: 27px;
    color: #1A1C1D;
}
.address_form{
    margin-top: 2vw;
}

.footer_buttons{
    margin-top:30px;
    margin-bottom:30px;
    display:flex;
    flex-direction: row;
    width:100%;
    justify-content: space-between;}


.footer_buttons button{
    border:none;
    font-family: Lato;
    font-style: normal;

    width:120px;
    height:40px;
    border-radius: 10px;
    font-weight: 700;
    text-align: center;

}

.footer_buttons #cancel{
    background-color:white;
    color:#8083A3;
    border:1px solid #8083A3;
    margin-right:80px;
}

.footer_buttons #update{
    color:white;
    background-color: #5446db;

}
/*
@media screen and (max-width: 1400px){
    .add_patient_form_item{
        width:30vw;
    }
    .address_form{
        margin-top:3vh;
    }
    .title_and_button{
        margin-bottom:1vh;
    }


}
*/
@media screen and (min-width: 1400px){
    .field_row{
        width:45% !important;
    }
    .add_patient_form_item{
        width:100%;
    }
}

.double_container{
    display:flex;
}
.val_compressed_details_container{
    width:calc( 100vw - 72px );
}
.field_row{
    margin-right:20px;
    width:45%;
}

.unstyled::-webkit-inner-spin-button,
.unstyled::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}
