*:focus {
  outline: none !important;
}

/*-----SIDE AND UPPER NAV STYLE SECTION-----*/
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.all_container {
  display: flex;
  flex-direction: row;
  flex: 1 auto;
}
.sidenav,
.content {
  height: 100vh;
}
.sidenav {
  background-color: #ffffff;
  width: 72px;
  transition: 0.3s;
  border-right: 1px solid #eceef5;
}
.sidenav_container {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}
.content {
  width: 85%;
}
.activated {
  width: 15%;
}

.sidenav_items {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.sidenav_container_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 40px;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
.sidenav_container_icons {
  margin-top: 3vh;
}
.sidenav_container_icons i {
  font-size: 16px;
  color: #8083a3;
}

.sidenav_container_item {
  display: flex;
  flex-direction: row;
}

.in {
  opacity: 1;
}
/*span {
  color: white;
  transition: 0.3s;
}*/

.item_section_left {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: white;
  margin-right: 3%;
}
.notification_dot {
  position: absolute;
  top: -2px;
  right: -2px;
  background: #1dd2c1;
  border-radius: 50%;
  width: 8px;
  height: 8px;
}
.upper_menu {
  height: 3.2rem;
  width: 100%;
  background-color: white;
  border-left: 1px solid #eceef5;
  border-bottom: 1px solid #eceef5;
}
.main_content {
  width: 100%;
  height: calc(100% - 3.2rem);
  border-top: 1px solid #eceef5;
  transition: 0.3s;
  display: flex;
  flex-direction: row;
}

.upper_menu {
  display: flex;
  align-items: center;
  height: 64px;
  width: 102.5%;
}
.upper_menu_container {
  margin: 0px 35px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.upper_menu_left_section {
  display: flex;

  align-items: center;
}
.upper_menu_left_section span {
  color: black;
  margin-left: 20px;
  font-size: 20px;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
}
.upper_menu_left_section .menu_icon_container {
  border: 1px solid #eceef5;
  padding: 14px 12px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.upper_menu_right_section {
  width: 60%;
  right: 3vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: 0;
}
.icon_search {
  margin: 5px 10px;
  border-radius: 10px;
  color: #8083a3;
}
#searchbar-icon {
  padding: 10px;
  margin-right: 20px !important;
  border: 1px #eceef5 solid;
  border-radius: 8px;
}
.upper_menu_right_section_img img {
  object-fit: cover;
  border-radius: 8px;
}

.hi {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  background: transparent !important;
  border: none !important;
  color: #8083a3;
}

/*Scrollbar*/
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 25px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;

  border-radius: 25px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.cont {
  padding-top: 1px;
  padding-bottom: 1px;
  width: 100%;
  cursor: pointer;
}
.open_navbar_bg_color {
  background-color: #f8f9fc;
}

.item_section_right {
  border: none !important;
}
.complet_logo {
  width: 81.82px;
  height: 25.45px;
}

.icon_search {
  margin: 5px 10px;

  border-radius: 10px;
  color: #8083a3;
}
#searchbar-icon {
  padding: 10px;
  margin-right: 20px !important;
  border: 1px #eceef5 solid;
  border-radius: 8px;
}
.upper_menu_right_section_img img {
  object-fit: cover;
  border-radius: 8px;
}
.active_icon {
  color: #5446db !important;
}
.active_container {
  color: #1a1c1d !important;
  border: 1px solid #eceef5;
  border-radius: 8px;
}
.active_container span {
  color: #1a1c1d ;
}

/*searchbar*/
.searchbar {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  border-radius: 8px;
}
#searchbar-icon {
  margin: 0 !important;
}

#searchbar-input {
  border: 0px solid;
  width: 50vw;
  height: 35px;
  font-size: 15px;
  outline: none;
  color: #606060;
  display: none;
  background: transparent;
  padding-right: 10px;
  border-radius: 8px;
}

.fa-times {
  display: none;
  font-size: 1rem;
  cursor: pointer;
}

.fa-search {
  font-size: 1rem;
  cursor: pointer;
}
#searchbar-cross {
  margin-right: 10px;
}
.searchbar input {
  padding-left: 10px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
}

a {
  text-decoration: none !important;
  color: inherit;
}

input:focus {
  outline: none;
}

#support_art {
  margin-top: 40%;
  margin-left: 20px;
  width: 150px;
  height: auto;
  transition: 1s;
}

.upper_menu_right_section_img button {
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  background: transparent !important;
}

.upper_menu_right_section_img button:focus {
  outline: none !important;
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.upper_menu_right_section_img {
  margin-right: -20px;
}
.profil_btn,
.disconnect_btn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10px;
}
.disconnect_btn img {
  border-radius: 0px !important;
}
.dropdown-menu {
  width: 300px;
  border: none !important;
  outline: none !important;
  -webkit-box-shadow: 0px 14px 49px -13px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: 0px 14px 49px -13px rgba(0, 0, 0, 0.27);
  box-shadow: 0px 14px 49px -13px rgba(0, 0, 0, 0.27);
  border-radius: 8px;
}
.profil_btn,
.disconnect_btn {
  margin-left: 15px !important;
}
.profil_btn span,
.disconnect_btn span {
  color: black;
  margin: 0px 10px;
}

.btn-group hr {
  width: 90% !important;
  margin: 10px 15px !important;
  padding: 0 !important;
}

.vertical-alignment-helper {
  display: table;
  height: 100%;
  width: 100%;
  pointer-events: none; /* This makes sure that we can still click outside of the modal to close it */
}
.vertical-align-center {
  /* To center vertically */
  display: table-cell;
  vertical-align: middle;
  pointer-events: none;
}
.modal-content {
  /* Bootstrap sets the size of the modal in the modal-dialog class, we need to inherit it */
  width: inherit;
  max-width: inherit; /* For Bootstrap 4 - to avoid the modal window stretching full width */
  height: inherit;
  /* To center horizontally */
  margin: 0 auto;
  pointer-events: all;
}
.modal-header {
  margin-bottom: 5%;
  margin-top: -6%;
  width: 640px;
  padding: 1rem 0rem;
}
.modal-header button span {
  font-size: 25px;
  margin-right: 0;
}
.modal-header span {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #060607;
  margin-left: -3%;
}
.modal-header,
.modal-body {
  border: none !important;
}
.modal-body {
  padding-top: 0px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px !important;
}
.modal-body span {
  color: #8083a3;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  width: 80%;/*
  margin: 10px;*/
}
.modal-body h2 {
  font-weight: bold;
  font-family: Lato;
  text-align: center;
  color: #1a1c1d;
}
.modal-footer {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.modal-footer button {
  width: 170px;
  height: 40px;
}
.modal-content {
  border-radius: 8px !important;
  width: 622px !important;
  height: 320px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.modal_disconnect_btn {
  background-color: #5446db;
  color: white;
  border-radius: 10px;
}
.modal_close_btn {
  background-color: #f6f6f6;
  color: #8083a3;
  border-radius: 10px;
}

.modal-footer button {
  border: none !important;
}

.item_section_right{
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.item_section_right button{
  text-align: left;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  background: transparent !important;
  border: none !important;
  color: #8083A3;
  font-size: 15px;
  padding: 0px;
}
.navbar-collapse ul{
  width: 80% !important;
}
.navbar-collapse{
  display: flex;
  justify-content: flex-end;
}
.navbar-collapse .nav-item button{
  display: flex;
  align-items: center;
  gap:10px;
  border: none;
  background: none;
}

.navbar-collapse ul{
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap:15px;
}
.nav-item button span{
  font-size: 14px;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  color: #8083A3;
}
