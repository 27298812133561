.container-bloc {
  margin: 20px;
}

.row-bloc {
  display: flex;
  margin-bottom: 20px;
  gap: 10px;
}

.block-bloc {
  height: 155px;
  flex: 1;
  background-color: #fff;
  padding: 10px;
  box-shadow: 5px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  max-width: 24%;
}

.icon-bloc {
  flex: 0.6;
  text-align: center;
  width: 40%;
}
.texts {
  width: 60%;
}
.icon-bloc img {
  width: 50px; /* Ajustez la taille de l'icône selon vos besoins */
  height: 50px;
}

.p-bloc {
  flex: 1;
  text-align: start;
}
.desc1 {
  color: #ccc;
}

.block-bloc:hover {
  cursor: pointer;
}

.block-bloc:hover > .texts span {
  color: #1a73e8;
}

.block-bloc:hover > .icon-bloc i {
  color: #1a73e8;
}
